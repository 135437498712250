<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Users
        </h1>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button class="mr-2" variant="outline-primary" @click="doExport">
          Export
        </b-button>
        <b-button
          class="mr-2"
          variant="outline-warning"
          @click="showImport = !showImport"
        >
          Import
        </b-button>
      </b-col>
    </b-row>
    <div v-if="showImport">
      <b-form-file
        v-model="importFile"
        accept="vnd.ms-excel"
        placeholder="Choose a file to import"
      ></b-form-file>
      <b-button @click="doImport" variant="primary" block class="my-2"
        >Import</b-button
      >
    </div>
    <vuetable
      ref="userstable"
      :api-mode="false"
      :fields="fields"
      :data="users"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="actions" scope="props">
        <font-awesome-icon
          icon="edit"
          class="action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit item"
          @click="editUser(props.rowData)"
          ref="btnEdit"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="trash"
          class="ml-2 action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove item"
          @click="deleteUser(props.rowData)"
        ></font-awesome-icon>
      </template>
    </vuetable>
  </b-overlay>
</template>
<script>
import Vuetable from "vuetable-2";
import UsersService from "@/services/UsersService";

export default {
  components: {
    Vuetable,
  },
  data() {
    return {
      showImport: false,
      isLoading: false,
      importFile: null,
      fields: ["first_name", "last_name", "email", "role", "actions"],
    };
  },
  computed: {
    users() {
      return this.$store.getters["Users/all"].data;
    },
  },
  methods: {
    onPaginationData(data) {
      console.log(data);
    },
    async doFetchAll() {
      this.isLoading = true;
      await this.$store.dispatch("Users/fetchAll");
      this.isLoading = false;
    },
    editUser(user) {
      this.$router.push({ name: "user-detail", params: { id: user.id } });
    },
    async doImport() {
      this.isLoading = true;
      const response = await UsersService.import(this.importFile);
      this.doFetchAll();
    },
    async doExport() {
      const response = await UsersService.export();
      const blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "users.xlsx";
      link.click();
      URL.revokeObjectURL(link.href);
    },
    async deleteUser(user) {
      this.isLoading = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove ${user.email}?`,
        {
          title: "Confirm delete",
          okVariant: "danger",
          centered: true,
        }
      );
      if (isConfirmed) {
        await this.$store.dispatch("Users/delete", user.id);
        return this.doFetchAll();
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.doFetchAll();
  },
};
</script>
<style lang="scss"></style>
